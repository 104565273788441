import { Fragment } from '@app/model';
import React from 'react';

const CommonFormsBar = ({ data }: { data: Fragment[] }) => {
    if (!data?.length) {
        return null;
    }
    return (
        <div className="blog-details-common-forms pb-30">
            <h3>Common Forms</h3>
            <ul>
                {data.map((form, index) => {
                    return (
                        <li key={index}>
                            <i className="bx bx-chevron-right" />
                            <a href={form?.link ?? `/${form.slug}`}>
                                {form.title}{' '}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default CommonFormsBar;
