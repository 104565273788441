import Link from 'next/link';
import React from 'react';

export const EditButton = ({ path, padding }: { path: string; padding?: string }) => {
    return (
        <div className='cms-edit-wrap' style={{ padding: padding ?? 0 }}>
            <Link href={path || "#"}>
                <a target={'_blank'} className='btn btn-primary btn-sm gradient-7 edit-btn'>Edit</a>
            </Link>
        </div>
    );
};