import { CMS_ENTRY_STATUS_PUBLISHED } from '@app/constants';
import { TCategory } from '@app/model';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const CategorySideBar = ({ data }: { data: TCategory[] }) => {
    if (!data?.length) {
        return null;
    }
    const show_max = 4;
    const activeAccordionId = `category-${data[0].id}`;
    return (
        <div className="category-sidebar">
            <h4 className="widget-title mb-3 border-bottom border-primary pb-1">Categories</h4>
            <Accordion defaultActiveKey={activeAccordionId} className="accordion accordion-wrapper">
                {data.map((item: TCategory, index) => {
                    const itemList = (item?.pages ?? [])
                        .filter((page) => page.status === CMS_ENTRY_STATUS_PUBLISHED);
                    const accordionId = `category-${item.id}`;
                    const heading = `heading-${index}`;

                    if (!Array.isArray(itemList) || itemList.length === 0) return null;
                    return <Accordion.Item key={accordionId} eventKey={accordionId} className="card plain accordion-item">
                        <Accordion.Header className="card-header ">
                            {item?.title}
                        </Accordion.Header>
                        <Accordion.Body className={`accordion-collapse`} aria-labelledby={heading} data-bs-parent="#categorySideBar">
                            <div className="card-body">
                                <ul className="icon-list bullet-primary text-reset">
                                    {itemList
                                        .slice(0, show_max)
                                        .map((page, index2: number) => (
                                            <li key={index2}>
                                                <span><i className="uil uil-arrow-right"></i></span>
                                                <a href={`/page/${page.slug}`} className="latest-post-entry hover link-primary">
                                                    <span>{page.title}</span>
                                                </a>
                                            </li>
                                        ))}
                                    {(itemList.length > show_max) ? (
                                        <li className="right-text">
                                            <a href={`/page/${item?.pages ? item?.pages[0]?.slug : ''}`} className="hover more">...view more</a>
                                        </li>
                                    ) : null}
                                </ul>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>;
                })}
            </Accordion>
        </div>
    );
};

export default CategorySideBar;
