import { EditButton } from '@app/components/atom/EditButton';
import { CMSBaseURL, CMSCollection } from '@app/constants';
import { OutputHtmlHelper } from '@app/helpers';
import { useAppData } from '@app/hooks';
import { Page as TPage, TCategory } from '@app/model';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

type HealthPlanPageSidebarProps = {
    data: TPage[];
    activeSlug?: string;
    categoryData?: Omit<TCategory, 'photo'> & { photo: string };
}
export const HealthPlanPageSidebar = ({ data, activeSlug, categoryData }: HealthPlanPageSidebarProps) => {
    const { isPreviewSite } = useAppData();
    const [navigatingSlug, setNavigatingSlug] = useState('');
    const router = useRouter();
    useEffect(() => {
        setNavigatingSlug('');
    }, [router.asPath]);
    const { excerpt, id } = categoryData ?? {};
    const defaultPageList: TPage[] = [];
    const categorizedPageList: Record<string, TPage[]> = data?.reduce((prev, curr) => {
        const listingSection = curr.listing_section;

        if (listingSection) {
            if (!prev[listingSection]) prev[listingSection] = [];
            prev[listingSection].push(curr);
        } else defaultPageList.push(curr);

        return prev;
    }, {} as { [key: string]: TPage[] | never });
    const path = `${CMSBaseURL}/${CMSCollection.CATEGORY}/${id}`;

    return (
        <div className="health-plan-category-sidebar mb-6">
            {defaultPageList.length !== 0 && <>
                <h4 className="widget-title my-3 mt-md-0 border-bottom border-primary pb-1">Page List</h4>
                <ul className="icon-list bullet-primary">
                    {defaultPageList
                        .map((entity) => {
                            const { title, slug, link, id } = entity;
                            const isNavigating = navigatingSlug === slug;
                            const isExternal = typeof link === 'string' && link.length > 0;

                            return <li key={id} className={activeSlug === slug ? '' : 'text-reset'}>
                                <span>
                                    <i className={`uil ${isNavigating ? 'uil-redo animate__rotate' : 'uil-file-bookmark-alt'}`} />
                                </span>

                                {isExternal
                                    ? <a
                                        href={link}
                                        className="latest-post-entry hover link-primary"
                                    >
                                        <span>{title}</span>
                                    </a>
                                    : <Link href={`/page/${slug}`}>
                                        <a
                                            href={'#'}
                                            className="latest-post-entry hover link-primary"
                                            onClick={() => { if (!router.asPath.includes(slug)) setNavigatingSlug(slug); }}
                                        >
                                            <span>{title}</span>
                                        </a>
                                    </Link>}
                            </li>;
                        })}
                </ul>
            </>}
            {categorizedPageList && Object
                .entries(categorizedPageList)
                .map(([categoryName, categoryPageList], index) => {
                    return categoryPageList.length > 0 && <div key={index}>
                        <h4 className="widget-title mb-3 border-bottom border-primary pb-1">{categoryName}</h4>
                        <ul className="icon-list bullet-primary">
                            {categoryPageList
                                .map((entity) => {
                                    const { title, slug, link, id } = entity;
                                    const isNavigating = navigatingSlug === slug;
                                    const isExternal = typeof link === 'string' && link.length > 0;

                                    return <li key={id} className={activeSlug === slug ? '' : 'text-reset'}>
                                        <span >
                                            <i className={`uil ${isNavigating ? 'uil-redo animate__rotate' : 'uil-list-ul'}`} />
                                        </span>
                                        {isExternal
                                            ? <a
                                                href={link}
                                                className="latest-post-entry hover link-primary"
                                            >
                                                <span>{title}</span>
                                            </a>
                                            : <Link href={`/page/${slug}`}>
                                                <a
                                                    href={'#'}
                                                    className="latest-post-entry hover link-primary"
                                                    onClick={() => { if (!router.asPath.includes(slug)) setNavigatingSlug(slug); }}
                                                >
                                                    <span>{title}</span>
                                                </a>
                                            </Link>}
                                    </li>;
                                })}
                        </ul>
                    </div>;
                })}
            {excerpt && <div className='position-relative'>
                <h4 className="widget-title mb-3 border-bottom border-primary pb-1">Summary</h4>
                <div>
                    {OutputHtmlHelper(
                        excerpt
                    )}
                </div>
                {isPreviewSite && <EditButton path={path} />}
            </div>}
        </div>
    );
};