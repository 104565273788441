import { CMS } from '@app/@gotecq-cms/data-layer';
import { MainLayout } from '@app/components/layout';
import { HealthPlanPageContent } from '@app/components/page/content';
import PageSideBars from '@app/components/page/page-side-bars';
import { CMSCollection, pageDefaultData } from '@app/constants';
import { getAssetFilePath, OutputHtmlHelper } from '@app/helpers';
import { useAppData } from '@app/hooks';
import { Fragment, Page as TPage, TCategory } from '@app/model';
import { GetStaticPropsContext } from 'next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { mergeWith } from 'ramda';
import React, { ReactNode, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

type TCategoryOmit = Omit<TCategory, 'photo'> & { photo: string };
type TPageOmit = Omit<TPage, 'photo'> & { photo: string };

type PageProps = {
    page_data: TPageOmit;
    sidebar_data: {
        sidebar_categories: TCategory[];
        sidebar_common_forms: Fragment[];
    };
    error: boolean;
    category_data: TCategoryOmit;
    page_in_category: TPage[];
}
export default function Page(props: PageProps) {
    const router = useRouter();
    const {
        page_data,
        sidebar_data,
        error,
    } = props;
    const { generalData, menuData } = useAppData();
    const { title, description, photo, content, content_text, template } =
        mergeWith<TPageOmit, Pick<TPageOmit, 'title' | 'description' | 'photo' | 'content'>>(
            (responseValue, defaultValue) => responseValue || defaultValue,
            page_data,
            { ...pageDefaultData, content: '' }
        );
    const page_title = title + ' - ' + generalData.name;
    const CMSInfo = {
        slug: CMSCollection.PAGE,
        id: `${page_data.id}`
    };

    useEffect(() => {
        if (error) {
            router.push('/404');
        }
    }, [error, router]);

    if (template === 'health-plan') return <HealthPlanPageContent {...props} CMSInfo={CMSInfo} />;
    if (template === 'full_width') {
        return (
            <MainLayout layoutProps={{ generalData, menuData }} CMSInfo={CMSInfo}>
                <NextSeo
                    title={page_title}
                    description={description}
                    openGraph={{ title: page_title, description }}
                />
                <section
                    id="about-us-page"
                    className="wrapper image-wrapper bg-image about-us-page-customize"
                    data-image-src={getAssetFilePath(photo)}
                    style={{
                        backgroundImage: `url(${getAssetFilePath(photo)})`,
                        backgroundPosition: 'top',
                    }}
                >
                    <div className="container d-flex align-items-center h-100">
                        <div className="row my-8 mt-md-16 mb-md-14 m-0" >
                            <div className="col-11 col-lg-12 m-auto">
                                <div className="row rounded shadow-lg overflow-hidden">
                                    <div className="col-lg-6 bg-white">
                                        <div className="blog px-2 py-4">
                                            <div className="content-markdown-wrapper">
                                                <ReactMarkdown>
                                                    {content}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="information-table col-lg-6 content-html-wrapper"
                                        style={{
                                            background: '#163270ce',
                                        }}
                                    >
                                        <div className="px-2 py-4">
                                            {content_text &&
                                                OutputHtmlHelper(content_text)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MainLayout>
        );
    }
    if (template === 'full_width_no_footer') {
        return (
            <MainLayout
                layoutProps={{ generalData, menuData, hideFooter: true }}
                CMSInfo={CMSInfo}
            >
                <NextSeo title={page_title} description={description} />
                <section
                    id="about-us-page"
                    className="wrapper image-wrapper bg-image"
                    data-image-src={getAssetFilePath(photo)}
                    style={{
                        backgroundImage: `url(${getAssetFilePath(photo)})`,
                        backgroundPosition: 'top',
                    }}
                >
                    <div className="container d-flex align-items-center h-100">
                        <div className="row mt-18  mb-4">
                            <div className="col-11 col-lg-12 m-auto">
                                <div className="row rounded shadow-lg overflow-hidden">
                                    <div className="col-md-6 bg-white">
                                        <div className="blog p-4">
                                            <div className="content-markdown-wrapper">
                                                <ReactMarkdown>
                                                    {content}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-6 content-html-wrapper"
                                        style={{
                                            background: 'rgba(0, 0, 0, 0.75)',
                                        }}
                                    >
                                        <div className="p-4 ">
                                            {content_text && (
                                                <div className="">
                                                    {OutputHtmlHelper(
                                                        content_text
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MainLayout>
        );
    }
    return (
        <MainLayout layoutProps={{ generalData, menuData }} CMSInfo={CMSInfo}>
            <NextSeo title={page_title} description={description} />
            <div className='page-section'>
                <section
                    className="wrapper image-wrapper bg-image bg-overlay text-white"
                    data-image-src={getAssetFilePath(photo)}
                    style={{
                        backgroundImage: `url(${getAssetFilePath(photo)})`,
                        backgroundPosition: 'top',
                    }}
                >
                    <div className="container py-10 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-9 mx-auto">
                                <div className="post-header">
                                    <h1 className="display-3 mb-1 text-white fw-normal">
                                        {title}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper bg-light" id="page-section">
                    <div className="container-lg py-8 py-md-12 pb-md-14 ">
                        <div className="row gx-10">
                            <div className="col-md-8">
                                <div className="blog classic-view">
                                    <div className="card">
                                        <div className="card-body p-4 p-md-8">
                                            <div className="content-markdown-wrapper">
                                                {content && (
                                                    <div className="content">
                                                        <ReactMarkdown>
                                                            {content}
                                                        </ReactMarkdown>
                                                    </div>
                                                )}
                                                {content_text && (
                                                    <div className="content-text">
                                                        {OutputHtmlHelper(
                                                            content_text
                                                        )}
                                                    </div>
                                                )}
                                                {!content && !content_text && (
                                                    <i>
                                                        This page has no content
                                                    </i>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <PageSideBars data={sidebar_data} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </MainLayout>
    );
}

Page.getLayout = (ctx: ReactNode) => {
    return ctx;
};

export async function getStaticPaths() {
    try {
        const paths = await CMS.getPaths({
            collection: `page`,
            key: `slug`,
            itemKey: `slug`,
            ignoreSite: false,
        });
        return { paths, fallback: false };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`ERROR: Could not retrieve paths in slug.tsx`);
        return { paths: [], fallback: false };
    }
}

export async function getStaticProps(props: GetStaticPropsContext<{ slug: string }>) {
    try {
        // const pages = await CMS.getPagesByCategoryId(cat_info.id);
        const page_data = await CMS.getPageBySlug(props.params?.slug as string);
        let category_data: TCategoryOmit | null = {} as TCategoryOmit;
        let page_in_category: TPage[] | null = [] as TPage[];

        if (page_data?.template === 'health-plan' && page_data?.category) {
            category_data = await CMS.getHealthPlanById(page_data?.category as number);
            page_in_category = await CMS.getPagesByCategoryId(page_data?.category as number);
        }

        const sidebar_categories = await CMS.getSideBarCategory();
        const sidebar_common_forms = await CMS.getSideBarCommonForms();

        if (page_data) {
            return {
                props: {
                    page_data,
                    sidebar_data: {
                        sidebar_categories,
                        sidebar_common_forms,
                    },
                    category_data,
                    page_in_category,
                    error: false,
                },
            };
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`ERROR: Couldn't find the page with slug `);
        return { props: { notFound: true, error: true } };
    }
}
