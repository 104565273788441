import { Fragment, TCategory } from '@app/model';
import React from 'react';
import CategorySideBar from '../sidebar/category-side-bar';
import CommonFormsBar from '../sidebar/common-forms-bar';

const PageSideBars = ({ data }: {
    data: {
        sidebar_categories: TCategory[];
        sidebar_common_forms: Fragment[];
    };
}) => {
    return (
        <aside className="blog-details-item pt-4 pt-lg-0">
            <div className="widget">
                <CategorySideBar data={data?.sidebar_categories} />
            </div>
            {data?.sidebar_common_forms && <div className="widget">
                <CommonFormsBar data={data?.sidebar_common_forms} />
            </div>}
        </aside>
    );
};

export default PageSideBars;
