import { MainLayout } from '@app/components/layout';
import { ASSETS_URL, pageDefaultData } from '@app/constants';
import { animateCSS, createCustomVariableSheet, OutputHtmlHelper } from '@app/helpers';
import { useAppData } from '@app/hooks';
import { Fragment, Page as PageInstance, TCategory } from '@app/model';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { mergeWith } from 'ramda';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { HealthPlanHeader } from '../header';
import { HealthPlanPageSidebar } from '../side-bar';

type TPage = Omit<PageInstance, 'photo'> & { photo: string }
type HealthPlanPageContentProps = {
    page_data: TPage;
    sidebar_data: {
        sidebar_categories: TCategory[];
        sidebar_common_forms: Fragment[];
    };
    CMSInfo?: {
        slug?: string;
        id?: string;
    };
    error: boolean;
    category_data: Omit<TCategory, 'photo'> & { photo: string };
    page_in_category: PageInstance[];
}

export function HealthPlanPageContent(props: HealthPlanPageContentProps,) {
    const router = useRouter();
    const {
        page_data,
        error,
        category_data,
        page_in_category,
        CMSInfo
    } = props;
    const {
        primary_color,
        secondary_color,
    } = category_data ?? {};
    const { generalData, menuData } = useAppData();
    const { title, content, content_text } =
        mergeWith<TPage, Pick<TPage, 'title' | 'description' | 'photo' | 'content'>>(
            (responseValue, defaultValue) => responseValue || defaultValue,
            page_data,
            { ...pageDefaultData, content: '' }
        );
    const page_title = title + ' - ' + generalData.name;

    useEffect(() => {
        if (error) {
            router.push('/404');
        }
    }, [error, router]);

    useEffect(() => {
        /**
         * Áp dụng custom style (nếu có) trong quá trình animation
         */
        const dynamicStyleSheet = document.querySelector('#js-dynamic-health-plan-category-style');
        if (dynamicStyleSheet && (primary_color || secondary_color)) {
            try {
                dynamicStyleSheet.innerHTML = `.singular-page.health-plan-variant {
                    ${createCustomVariableSheet(primary_color, secondary_color)}
                }`;
            } catch (e) {
            }
        }
        /**
         * Áp dụng animation để làm mượt quá trình đổi page
         */
        animateCSS('.singular-page-content.card', 'fadeIn')
            .then(() => {
                document.querySelector('.singular-page-content.card')?.classList.remove('animate__animated', 'animate__fadeIn');
            });
    }, [primary_color, router.asPath, secondary_color]);

    return (
        <MainLayout layoutProps={{ generalData, menuData }} CMSInfo={CMSInfo}>
            <NextSeo title={page_title} description={page_data?.description ? page_data?.description : ''} />
            <div className='singular-page health-plan-variant'>
                <HealthPlanHeader categoryData={category_data} />
                <section className="inner-page">
                    <div className="container py-6 py-md-8 pb-md-14">
                        <div className="row">
                            <div className="col-lg-3 order-2 order-md-1">
                                <HealthPlanPageSidebar
                                    data={page_in_category}
                                    activeSlug={page_data?.slug}
                                    categoryData={category_data}
                                />
                            </div>
                            <div className="col-lg-9 order-1 order-md-2">
                                <div className="blog classic-view">
                                    <div className="singular-page-content card overflow-hidden">
                                        {page_data.photo &&
                                            <section className="wrapper image-wrapper" >
                                                <img src={`${ASSETS_URL}/${page_data.photo}`} className='fit-image' alt="" />
                                            </section>
                                        }
                                        <div className="card-body p-4 p-md-8">
                                            <div className="col-12 col-md-12">
                                                <h1 className="page-title display-3 mb-4 title-underline">
                                                    {page_data?.title ?? 'Untitled Page'}
                                                </h1>
                                            </div>
                                            <div className="content-markdown-wrapper">
                                                {content && (
                                                    <div className="content">
                                                        <ReactMarkdown>
                                                            {content}
                                                        </ReactMarkdown>
                                                    </div>
                                                )}
                                                {content_text && (
                                                    <div className="content-text">
                                                        {OutputHtmlHelper(
                                                            content_text
                                                        )}
                                                    </div>
                                                )}
                                                {!content && !content_text && (
                                                    <i>
                                                        This page has no content
                                                    </i>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </MainLayout>
    );
}