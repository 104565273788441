import { DefaultPageImageId } from "@app/constants";
import { getAssetFilePath } from "@app/helpers";
import { TCategory } from "@app/model";

export type HealthPlanHeaderProps = {
    categoryData: Omit<TCategory, 'photo'> & { photo: string };
}
export const HealthPlanHeader = ({ categoryData }: HealthPlanHeaderProps) => {
    const {
        photo: categoryPhoto,
        logo: categoryLogo,
        title: categoryTitle,
    } = categoryData ?? {};

    return <section
        className="wrapper image-wrapper bg-image text-white breadcrumbs wrapper-border shadow"
        data-image-src={getAssetFilePath(categoryPhoto ?? DefaultPageImageId)}
        style={{
            backgroundImage: `url(${getAssetFilePath(categoryPhoto ?? DefaultPageImageId)})`,
            backgroundPosition: 'top',
        }}
    >
        <div className="container py-6">
            <div className="row">
                <div className="col-md-8">
                    <div className="post-header">
                        <div className="post-category text-line text-white">
                            Health Plan
                        </div>
                        <h1 className="post-title display-4 mb-0">
                            {categoryTitle}
                        </h1>
                    </div>
                </div>
                {categoryLogo && <div className="health-plan-logo col-md-4 mb-n2 mt-4 my-md-n4 d-inline-flex">
                    <div className="logo-container p-2 bg-white rounded-1 d-inline-flex align-items-center">
                        <img className=""
                            src={getAssetFilePath(categoryLogo)} alt={`${categoryTitle} Logo`} />
                    </div>
                </div>}
            </div>
        </div>
    </section>;
};